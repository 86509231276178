/*screen breaks */
/*colors*/
html,
body,
header,
nav,
h1,
a,
h3,
h2,
ul,
li,
input,
strong,
main,
button,
i,
section,
img,
div,
h2,
p,
form,
fieldset,
textarea,
span,
article,
footer,
time,
small {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
  box-sizing: inherit;
  background: transparent;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: #1a202c;
  color: #2a317d;
  font-family: Helvetica, Arial, sans-serif;
  font-family: 'Fugue Regular', Helvetica, Arial, sans-serif;
  background: #fff3e3;
}

.fallback-font {
  font-family: Helvetica, Arial, sans-serif;
}

body {
  box-sizing: border-box;
}

ul {
  list-style: none;
}
